import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import api from '@/api'
import { mapOptions, sortListAlphabetically } from '@/utils/mappers'
import { CLASSES_LABELS as classNames } from '@/constants/classes'
import { useTranslationStore } from '@/stores/translation'
import { prepareObject } from '@/views/objects/js/prepareObject'
import { OBJECT_GROUP_TYPES } from '@/constants/objects'

export const useObjectRegistryStore = defineStore('objectRegistry', () => {
  const translationStore = useTranslationStore()

  // Table list
  const tableList = ref([])
  const aggregations = ref({})
  const tableListCount = ref(0)
  const isTableListLoading = ref(false)

  function fetchTableList(requestData) {
    isTableListLoading.value = true
    return api.objectRegistry
      .searchUseobjects(requestData)
      .then(response => {
        tableList.value = response.list.map(object => ({
          ...object,
          network: object.groups?.find(group => group.groupTypeId === OBJECT_GROUP_TYPES.NETWORK),
        }))
        tableListCount.value = response.count
        aggregations.value = response.aggregations?.aggregations
      })
      .finally(() => (isTableListLoading.value = false))
  }

  // Card fields
  const currentUseObject = ref(null)
  const isCurrentUseObjectLoading = ref(false)

  function fetchUseObject(id) {
    isCurrentUseObjectLoading.value = true
    return api.objectRegistry
      .getUseObject(id)
      .then(response => {
        currentUseObject.value = prepareObject(response, typesForSelect, classNames)
      })
      .finally(() => (isCurrentUseObjectLoading.value = false))
  }

  function clearUseObject() {
    currentUseObject.value = null
  }

  // Categories for select
  const categories = ref([])
  const categoriesForSelect = computed(() => {
    const mappedCategories = categories.value.map(el => {
      return {
        ...el,
        name: `${translationStore.translateString(el.name)} (${translationStore.translateString(
          el.shortName,
        )})`,
      }
    })
    return sortListAlphabetically(mappedCategories, 'name')
  })

  function fetchCategoriesForSelect() {
    return api.objectRegistry.getCategoriesForSelect().then(response => {
      categories.value = response.list
    })
  }

  // Types for select
  const typesForSelect = ref([])

  function fetchTypesForSelect() {
    return api.objectRegistry
      .getTypesForSelect()
      .then(response => (typesForSelect.value = mapOptions(response.list)))
  }
  // Classes

  const classesList = ref([])

  function fetchClassesList() {
    return api.objectRegistry.getClasses().then(response => {
      //TODO: временно скрыл виртуальный объект. До решения HG3-1530
      // classesList.value = response.list
      classesList.value = response.list.filter(type => type.id !== '3')
    })
  }

  // Groups

  const groupsList = ref([])

  function fetchGroupsList() {
    return api.objectRegistry.getGroups().then(response => (groupsList.value = response.list))
  }

  function removeObject() {
    return api.objectRegistry.removeUseobject(currentUseObject.value.id).catch(() => {})
  }

  return {
    tableList,
    aggregations,
    isTableListLoading,
    tableListCount,
    fetchTableList,

    currentUseObject,
    isCurrentUseObjectLoading,
    fetchUseObject,
    clearUseObject,

    categoriesForSelect,
    fetchCategoriesForSelect,

    typesForSelect,
    fetchTypesForSelect,

    classesList,
    fetchClassesList,

    groupsList,
    fetchGroupsList,

    removeObject,
  }
})

import { IConfigElement, CONFIG_ELEMENT_TYPES } from '@/models/preset'

// Imaginary preset

const sideMenuConfig: IConfigElement<CONFIG_ELEMENT_TYPES.SideMenuPreset> = {
  id: '',
  name: '',
  type: 'SideMenuPreset',
  widgetId: 'side_menu',
  dbField: null,
  dataFieldPath: null,
  prefs: {
    label: null,
    hidden: 'false',
    disabled: null,
    editable: null,
    presetName: null,
    presetParams: null,
  },
  children: [
    {
      id: '',
      name: 'Раздел Договоры',
      type: 'SideMenuItemPreset',
      widgetId: 'side_menu_contracts',
      dbField: null,
      dataFieldPath: null,
      prefs: {
        label: 'Договоры',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: null,
        presetParams: null,
        options: {
          navigateTo: {
            name: 'Contracts',
          },
          icon: 'DocumentOutline',
        },
      },
      children: [],
    },
    {
      id: '',
      name: 'Раздел Платежи',
      type: 'SideMenuItemPreset',
      widgetId: 'side_menu_payments',
      dbField: null,
      dataFieldPath: null,
      prefs: {
        label: 'Платежи',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: null,
        presetParams: null,
        options: {
          navigateTo: {
            name: 'Payments',
          },
          icon: 'CashOutline',
        },
      },
      children: [],
    },
    {
      id: '',
      name: 'Раздел Загрузки поступлений',
      type: 'SideMenuItemPreset',
      widgetId: 'side_menu_paymentImportPackages',
      dbField: null,
      dataFieldPath: null,
      prefs: {
        label: 'Загрузки поступлений',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: null,
        presetParams: null,
        options: {
          navigateTo: {
            name: 'PaymentImportPackages',
          },
          icon: 'EnterOutline',
        },
      },
      children: [],
    },
    {
      id: '',
      name: 'Раздел Субъекты',
      type: 'SideMenuItemPreset',
      widgetId: 'side_menu_subjects',
      dbField: null,
      dataFieldPath: null,
      prefs: {
        label: 'Субъекты',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: null,
        presetParams: null,
        options: {
          navigateTo: {
            name: 'SubjectRegistry',
          },
          icon: 'BriefcaseOutline',
        },
      },
      children: [],
    },
    {
      id: '',
      name: 'Раздел Объекты',
      type: 'SideMenuItemPreset',
      widgetId: 'side_menu_objects',
      dbField: null,
      dataFieldPath: null,
      prefs: {
        label: 'Объекты',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: null,
        presetParams: null,
        options: {
          navigateTo: {
            name: 'ObjectRegistry',
          },
          icon: 'CubeOutline',
        },
      },
      children: [],
    },
    {
      id: '',
      name: 'Раздел Лицензии СМИ',
      type: 'SideMenuItemPreset',
      widgetId: 'side_menu_licensesRegistry',
      dbField: null,
      dataFieldPath: null,
      prefs: {
        label: 'Лицензии СМИ',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: null,
        presetParams: null,
        options: {
          navigateTo: {
            name: 'LicensesRegistry',
          },
          icon: 'CardOutline',
        },
      },
      children: [],
    },
    {
      id: '',
      name: 'Раздел Справочники',
      type: 'SideMenuItemPreset',
      widgetId: 'side_menu_dictionary',
      dbField: null,
      dataFieldPath: null,
      prefs: {
        label: 'Справочники',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: null,
        presetParams: null,
        options: {
          navigateTo: null,
          icon: 'FileTrayFullOutline',
        },
      },
      children: [
        {
          id: '',
          name: 'Раздел Подразделения',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_departmentsRegistry',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Подразделения',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: { name: 'DepartmentsRegistry' },
              icon: 'PeopleOutline',
            },
          },
          children: [],
        },
        {
          id: '',
          name: 'Раздел Типы документов',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_documentTypesRegistry',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Типы документов',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: { name: 'DocumentTypesRegistry' },
              icon: 'ClipboardOutline',
            },
          },
          children: [],
        },
        {
          id: '',
          name: 'Раздел Конфигурация алгоритмов идентификации',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_identificationConfigurationRegistry',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Конфигурация алгоритмов идентификации',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: { name: 'IdentificationConfigurationRegistry' },
              icon: 'FileTrayStackedOutline',
            },
          },
          children: [],
        },
        {
          id: '',
          name: 'Раздел Исключения из идентификации',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_identificationExceptions',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Исключения из идентификации',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: { name: 'IdentificationExceptions' },
              icon: 'NewspaperOutline',
            },
          },
          children: [],
        },
        {
          id: '',
          name: 'Раздел Регионы',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_regions',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Регионы',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: { name: 'Regions' },
              icon: 'GlobeOutline',
            },
          },
          children: [],
        },
        {
          id: '',
          name: 'Раздел Города',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_cities',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Города',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: { name: 'Cities' },
              icon: 'NavigateOutline',
            },
          },
          children: [],
        },
        {
          id: '',
          name: 'Раздел Должности',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_positions',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Должности',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: { name: 'Positions' },
              icon: 'WalkOutline',
            },
          },
          children: [],
        },
        {
          id: '',
          name: 'Раздел Компоненты наименований',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_contact_registry',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Компоненты наименований',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: { name: 'NameComponentRegistry' },
              icon: 'PersonCircleOutline',
            },
          },
          children: [],
        },
        {
          id: '',
          name: 'Раздел Типы сетевых источников',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_net_source_types',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Типы сетевых источников',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: { name: 'NetSourceTypes' },
              icon: 'DocumentOutline',
            },
          },
          children: [],
        },
        {
          id: '',
          name: 'Раздел Банки',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_bankRegistry',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Банки',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: {
                name: 'BankRegistry',
              },
              icon: 'BusinessOutline',
            },
          },
          children: [],
        },
        {
          id: '',
          name: 'Раздел Счета получателя',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_bankAccounts',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Счета получателя',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: {
                name: 'BankAccounts',
              },
              icon: 'WalletOutline',
            },
          },
          children: [],
        },
        {
          id: '',
          name: 'Раздел Категории пользователя',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_userCategories',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Категории пользователя',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: {
                name: 'UserCategories',
              },
              icon: 'CopyOutline',
            },
          },
          children: [],
        },
        {
          id: '',
          name: 'Раздел Группы объектов',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_useobjectGroups',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Группы объектов',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: {
                name: 'UseobjectGroups',
              },
              icon: 'ListOutline',
            },
          },
          children: [],
        },
      ],
    },
    {
      id: '',
      name: 'Раздел Администрирование',
      type: 'SideMenuItemPreset',
      widgetId: 'side_menu_admin',
      dbField: null,
      dataFieldPath: null,
      prefs: {
        label: 'Администрирование',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: null,
        presetParams: null,
        options: {
          navigateTo: null,
          icon: 'DesktopOutline',
        },
      },
      children: [
        {
          id: '',
          name: 'Раздел Настройки UI',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_settingsUI',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Настройки UI',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: { name: 'SettingsUI' },
              icon: 'SettingsOutline',
            },
          },
          children: [],
        },
        {
          id: '',
          name: 'Раздел Переводы',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_translations',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Переводы',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: null,
              icon: 'LanguageOutline',
            },
          },
          children: [
            {
              id: '',
              name: 'Раздел Токены',
              type: 'SideMenuItemPreset',
              widgetId: 'side_menu_adminTranslationsTokens',
              dbField: null,
              dataFieldPath: null,
              prefs: {
                label: 'Токены',
                hidden: 'false',
                disabled: null,
                editable: null,
                presetName: null,
                presetParams: null,
                options: {
                  navigateTo: { name: 'AdminTranslationsTokens' },
                  icon: 'ReorderFourOutline',
                },
              },
              children: [],
            },
            {
              id: '',
              name: 'Раздел Переводы',
              type: 'SideMenuItemPreset',
              widgetId: 'side_menu_adminTranslations',
              dbField: null,
              dataFieldPath: null,
              prefs: {
                label: 'Переводы',
                hidden: 'false',
                disabled: null,
                editable: null,
                presetName: null,
                presetParams: null,
                options: {
                  navigateTo: { name: 'AdminTranslations' },
                  icon: 'ReorderFourOutline',
                },
              },
              children: [],
            },
          ],
        },
        {
          id: '',
          name: 'Конфигурации загрузки',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_adminPaymentImportConfigurationss',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Конфигурации загрузки',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: { name: 'AdminPaymentImportConfigurations' },
              icon: 'PushOutline',
            },
          },
          children: [],
        },
        {
          id: '',
          name: 'Операторы',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_adminOperators',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Операторы',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: { name: 'Operators' },
              icon: 'PersonOutline',
            },
          },
          children: [],
        },
      ],
    },
    {
      id: '',
      name: 'Раздел Технический',
      type: 'SideMenuItemPreset',
      widgetId: 'side_menu_admin',
      dbField: null,
      dataFieldPath: null,
      prefs: {
        label: 'Технический',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: null,
        presetParams: null,
        options: {
          navigateTo: null,
          icon: 'CodeSlashOutline',
        },
      },
      children: [
        {
          id: '',
          name: 'Раздел Пресеты',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_techPresets',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Пресеты',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: { name: 'TechPresets' },
              icon: 'CodeWorking',
            },
          },
          children: [],
        },
        {
          id: '',
          name: 'Раздел Настраиваемая ширина столбцов',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_testConfigTableColumnSize',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Настраиваемая ширина столбцов',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: { name: 'TestConfigTableColumnSize' },
              icon: 'CodeWorking',
            },
          },
          children: [],
        },
        {
          id: '',
          name: 'Раздел Контрастная тема',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_testConfigTableTheme',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Контрастная тема',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: { name: 'TestConfigTableTheme' },
              icon: 'CodeWorking',
            },
          },
          children: [],
        },
        {
          id: '',
          name: 'Раздел Создание конфигураций форм',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_admin_create',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Создание конфигураций форм',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: { name: 'AdminConfigCreate' },
              icon: 'Build',
            },
          },
          children: [],
        },
        {
          id: '',
          name: 'Раздел Редактирование конфигураций форм',
          type: 'SideMenuItemPreset',
          widgetId: 'side_menu_admin_edit',
          dbField: null,
          dataFieldPath: null,
          prefs: {
            label: 'Редактирование конфигураций форм',
            hidden: 'false',
            disabled: null,
            editable: null,
            presetName: null,
            presetParams: null,
            options: {
              navigateTo: { name: 'AdminConfigEdit' },
              icon: 'Build',
            },
          },
          children: [],
        },
      ],
    },
  ],
}

export default sideMenuConfig

import request, { IResponseEntity, IResponseList } from '@/api/request'
import { ICreateStageData, IEditStageData, IStage, IStageSettings } from '@/models/entities/stage'
import {
  IConflictUseobject,
  ICreateConflictUseobjectData,
} from '@/models/entities/conflictUseobject'

export const getStageSettings = (params: unknown) => {
  return request<IResponseList<IStageSettings>>({
    url: `/api/v1/user_development/stage_settings/list`,
    method: 'GET',
    params,
  })
}

export const getStages = (params: unknown) => {
  return request<IResponseList<IStage>>({
    url: `/api/v1/user_development/stages`,
    method: 'GET',
    params,
  })
}

export const getStage = (id: IStage['id']) => {
  return request<IResponseEntity<IStage>>({
    url: `/api/v1/user_development/stages/${id}`,
    method: 'GET',
  })
}

export const createStage = (data: ICreateStageData) => {
  return request<IResponseEntity<IStage>>({
    url: `/api/v1/user_development/stages`,
    method: 'POST',
    data,
  })
}

export const editStage = (id: IStage['id'], data: IEditStageData) => {
  return request<IResponseEntity<IStage>>({
    url: `/api/v1/user_development/stages/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteStage = (id: IStage['id']) => {
  return request({
    url: `/api/v1/user_development/stages/${id}`,
    method: 'DELETE',
  })
}

export const getConflictUseobjects = (params: unknown) => {
  return request<IResponseList<IConflictUseobject>>({
    url: `/api/v1/user_development/conflict_useobjects`,
    method: 'GET',
    params,
  })
}

export const getConflictUseobject = (id: IConflictUseobject['id']) => {
  return request<IResponseEntity<IConflictUseobject>>({
    url: `/api/v1/user_development/conflict_useobjects/${id}`,
    method: 'GET',
  })
}

export const createConflictUseobject = (data: ICreateConflictUseobjectData) => {
  return request({
    url: `/api/v1/user_development/conflict_useobjects`,
    method: 'POST',
    data,
  })
}

export const deleteConflictUseobject = (id: string) => {
  return request({
    url: `/api/v1/user_development/conflict_useobjects/${id}`,
    method: 'DELETE',
  })
}

import qs from 'qs'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import api from '@/api'
import { useAuthStore } from '@/stores/auth'
import { useUserSettingsStore } from '@/stores/settings'
import { setPageTitle, Breadcrumb } from '@/utils/baseHelpers'

declare module 'vue-router' {
  interface RouteMeta {
    breadcrumb?: Breadcrumb[]
  }
}

const routes: RouteRecordRaw[] = [
  // temp solution
  {
    path: '/',
    redirect: () => '/object_registry',
  },
  {
    path: '/object_form/:id?',
    name: 'ObjectForm',
    component: () => import('@/views/objects/ObjectsFormWrapper.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/subject_form/:id?',
    name: 'SubjectForm',
    component: () => import('@/views/subjects/SubjectsForm.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/object_registry',
    name: 'ObjectRegistry',
    component: () => import('@/views/objects/ObjectsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'objectRegistry',
          text: {
            type: 'simple',
            options: {
              value: 'Объекты',
            },
          },
        },
      ],
    },
  },
  {
    path: '/bank_accounts',
    name: 'BankAccounts',
    component: () => import('@/views/bankAccounts/BankAccounts.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'accounts',
          text: {
            type: 'simple',
            options: {
              value: 'Счета получателя',
            },
          },
        },
      ],
    },
  },
  {
    path: '/bank_account_form/:id?',
    name: 'BankAccountForm',
    component: () => import('@/views/bankAccount/BankAccountForm.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/bank_registry',
    name: 'BankRegistry',
    component: () => import('@/views/bankRegistry/BankRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'bankRegistry',
          text: {
            type: 'simple',
            options: {
              value: 'Банки',
            },
          },
        },
      ],
    },
  },
  {
    path: '/subject_registry',
    name: 'SubjectRegistry',
    component: () => import('@/views/subjects/SubjectsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'subjectRegistry',
          text: {
            type: 'simple',
            options: {
              value: 'Субъекты',
            },
          },
        },
      ],
    },
  },
  {
    path: '/object_registry/:id/:tab?',
    name: 'ObjectRegistryCard',
    component: () => import('@/views/objects/ObjectsRegistryCard.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'objects',
          text: {
            type: 'simple',
            options: {
              value: 'Объекты',
            },
          },
          to: { name: 'ObjectRegistry' },
        },
        {
          name: 'objectName',
          text: {
            type: 'fromStore',
            options: {
              path: 'objectRegistry/currentUseObject',
              field: 'name',
            },
          },
        },
        {
          name: 'tab',
          text: {
            type: 'routePath',
            options: {
              param: 'tab',
              map: {
                finance: 'Финансовые характеристики',
                contacts: 'Контакты',
                reports: 'Договоры',
                voiceOver: 'Зоны озвучивания',
                tech: 'Технические устройства',
              },
            },
          },
        },
      ],
    },
  },
  {
    path: '/contracts',
    name: 'Contracts',
    component: () => import('@/views/contracts/ContractsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'contracts',
          text: {
            type: 'simple',
            options: {
              value: 'Договоры',
            },
          },
        },
      ],
    },
  },
  {
    path: '/contracts_card/:id/:tab?',
    name: 'ContractsCard',
    component: () => import('@/views/contracts/ContractsCard.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'contracts',
          text: {
            type: 'simple',
            options: {
              value: 'Договоры',
            },
          },
          to: { name: 'Contracts' },
        },
        {
          name: 'contractName',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'displayName',
            },
          },
        },
        {
          name: 'tab',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'tab',
            },
          },
        },
      ],
    },
  },
  {
    path: '/contracts_project_card/:id/:tab?',
    name: 'ContractsProjectCard',
    component: () => import('@/views/contracts/ContractsProjectCard.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'contracts',
          text: {
            type: 'simple',
            options: {
              value: 'Проекты',
            },
          },
          to: { name: 'Contracts' },
        },
        {
          name: 'contractName',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'displayName',
            },
          },
        },
        {
          name: 'tab',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'tab',
            },
          },
        },
      ],
    },
  },
  {
    path: '/contracts_project_card/:id/conflict',
    name: 'ContractsConflictCard',
    component: () => import('@/views/contracts/ContractConflictCard.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'contracts',
          text: {
            type: 'simple',
            options: {
              value: 'Проекты',
            },
          },
          to: { name: 'Contracts' },
        },
        {
          name: 'contractName',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'displayName',
            },
          },
          to: { name: 'ContractsProjectCard' },
        },
        {
          name: 'conflict',
          text: {
            type: 'simple',
            options: {
              value: 'Конфликт',
            },
          },
        },
      ],
    },
  },
  {
    path: '/contracts_form/:id?',
    name: 'ContractsForm',
    component: () => import('@/views/contracts/ContractsForm.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/payments',
    name: 'Payments',
    component: () => import('@/views/payments/PaymentsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'payments',
          text: {
            type: 'simple',
            options: {
              value: 'Платежи',
            },
          },
        },
      ],
    },
  },
  {
    path: '/payment_import_packages',
    name: 'PaymentImportPackages',
    component: () => import('@/views/paymentImportPackages/PaymentImportPackages.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'paymentImportPackages',
          text: {
            type: 'simple',
            options: {
              value: 'Загрузки поступлений',
            },
          },
        },
      ],
    },
  },
  {
    path: '/config_form',
    name: 'ConfigForm',
    component: () => import('@/views/testConfig/ConfigForm.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/config_form2',
    name: 'ConfigForm2',
    component: () => import('@/views/testConfig/ConfigForm2.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/config_table',
    name: 'ConfigTable',
    component: () => import('@/views/testConfig/ConfigTable.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/config_table2',
    name: 'ConfigTable2',
    component: () => import('@/views/testConfig/ConfigTable2.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/config_registry',
    name: 'ConfigRegistry',
    component: () => import('@/views/testConfig/ConfigRegistry.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/config_registry2',
    name: 'ConfigRegistry2',
    component: () => import('@/views/testConfig/ConfigRegistry2.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/config_registry3',
    name: 'ConfigRegistry3',
    component: () => import('@/views/testConfig/ConfigRegistry3.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/config_table_theme',
    name: 'TestConfigTableTheme',
    component: () => import('@/views/testConfig/TestConfigTableTheme.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/config_table_column_size',
    name: 'TestConfigTableColumnSize',
    component: () => import('@/views/testConfig/TestConfigTableColumnSize.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/AuthLogin.vue'),
    meta: {
      layout: 'auth-layout',
      breadcrumb: [
        {
          name: 'login',
          text: {
            type: 'simple',
            options: {
              value: 'Вход',
            },
          },
        },
      ],
    },
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: () => import('@/views/auth/AuthPasswordConfirm.vue'),
    meta: {
      layout: 'auth-layout',
    },
  },
  {
    path: '/profiles',
    name: 'Profiles',
    component: () => import('@/views/auth/AuthProfiles.vue'),
    meta: {
      layout: 'auth-layout',
    },
  },
  {
    path: '/settings-ui',
    name: 'SettingsUI',
    component: () => import('@/views/admin/settings/AdminSettings.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'settingsUI',
          text: {
            type: 'simple',
            options: {
              value: 'Настройки UI',
            },
          },
        },
      ],
    },
  },
  {
    path: '/operators',
    name: 'Operators',
    component: () => import('@/views/admin/operators/OperatorsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'operators',
          text: {
            type: 'simple',
            options: {
              value: 'Операторы',
            },
          },
        },
      ],
    },
  },
  {
    path: '/tech-presets',
    name: 'TechPresets',
    component: () => import('@/views/admin/presets/TechPresets.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'techPresets',
          text: {
            type: 'simple',
            options: {
              value: 'Пресеты',
            },
          },
        },
      ],
    },
  },
  {
    path: '/admin_config_create',
    name: 'AdminConfigCreate',
    component: () => import('@/views/admin/formConfig/AdminFormConfigCreate.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'adminConfigCreate',
          text: {
            type: 'simple',
            options: {
              value: 'Создать конфигурацию формы',
            },
          },
        },
      ],
    },
  },
  {
    path: '/admin_config_edit',
    name: 'AdminConfigEdit',
    component: () => import('@/views/admin/formConfig/AdminFormConfigEdit.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'adminConfigEdit',
          text: {
            type: 'simple',
            options: {
              value: 'Изменить конфигурацию формы',
            },
          },
        },
      ],
    },
  },
  {
    path: '/user_categories',
    name: 'UserCategories',
    component: () => import('@/views/userCategories/UserCategories.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'userCategories',
          text: {
            type: 'simple',
            options: {
              value: 'Категории пользователя',
            },
          },
        },
      ],
    },
  },
  {
    path: '/user_categories/management',
    name: 'UserCategoriesManagement',
    component: () => import('@/views/userCategories/UserCategoriesManagement.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'userCategoriesManagement',
          text: {
            type: 'simple',
            options: {
              value: 'Управление категориями пользователя',
            },
          },
        },
      ],
    },
  },
  {
    path: '/identification_exceptions',
    name: 'IdentificationExceptions',
    component: () => import('@/views/identificationExceptions/IdentificationExceptions.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionary',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'IdentificationExceptions',
          text: {
            type: 'simple',
            options: {
              value: 'Исключения из идентификации',
            },
          },
        },
      ],
    },
  },
  {
    path: '/translations/tokens',
    name: 'AdminTranslationsTokens',
    component: () => import('@/views/admin/translations/TranslationsTokenRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'admin',
          text: {
            type: 'simple',
            options: {
              value: 'Администрирование',
            },
          },
        },
        {
          name: 'translations',
          text: {
            type: 'simple',
            options: {
              value: 'Переводы',
            },
          },
        },
        {
          name: 'tokens',
          text: {
            type: 'simple',
            options: {
              value: 'Токены',
            },
          },
        },
      ],
    },
  },
  {
    path: '/payment_import_configurations',
    name: 'AdminPaymentImportConfigurations',
    component: () =>
      import('@/views/admin/paymentImportConfigurations/PaymentImportConfigurations.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'adminPaymentImportConfigurations',
          text: {
            type: 'simple',
            options: {
              value: 'Конфигурации загрузки',
            },
          },
        },
      ],
    },
  },
  {
    path: '/payment_import_configuration_form/:id?',
    name: 'PaymentImportConfigurationForm',
    component: () =>
      import('@/views/admin/paymentImportConfigurations/PaymentImportConfigurationForm.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/departments',
    name: 'DepartmentsRegistry',
    component: () => import('@/views/departments/DepartmentsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'departments',
          text: {
            type: 'simple',
            options: {
              value: 'Подразделения',
            },
          },
        },
      ],
    },
  },
  {
    path: '/departments_form/:id?',
    name: 'DepartmentsForm',
    component: () => import('@/views/departments/DepartmentsForm.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/documents_form/:id?',
    name: 'DocumentsCreateEditForm',
    component: () => import('@/views/documents/DocumentsCreateEditForm.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/document-types',
    name: 'DocumentTypesRegistry',
    component: () => import('@/views/documentTypes/DocumentTypesRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'documentTypes',
          text: {
            type: 'simple',
            options: {
              value: 'Типы документов',
            },
          },
        },
      ],
    },
  },
  {
    path: '/regions',
    name: 'Regions',
    component: () => import('@/views/regions/RegionsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'regions',
          text: {
            type: 'simple',
            options: {
              value: 'Регионы',
            },
          },
        },
      ],
    },
  },
  {
    path: '/cities',
    name: 'Cities',
    component: () => import('@/views/cities/CitiesRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'cities',
          text: {
            type: 'simple',
            options: {
              value: 'Города',
            },
          },
        },
      ],
    },
  },
  {
    path: '/net_source_types',
    name: 'NetSourceTypes',
    component: () => import('@/views/netSourceTypes/NetSourceTypes.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'netSourceTypes',
          text: {
            type: 'simple',
            options: {
              value: 'Типы сетевых источников',
            },
          },
        },
      ],
    },
  },
  {
    path: '/positions',
    name: 'Positions',
    component: () => import('@/views/positions/PositionsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'positions',
          text: {
            type: 'simple',
            options: {
              value: 'Должности',
            },
          },
        },
      ],
    },
  },
  {
    path: '/translations',
    name: 'AdminTranslations',
    component: () => import('@/views/admin/translations/TranslationsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'admin',
          text: {
            type: 'simple',
            options: {
              value: 'Администрирование',
            },
          },
        },
        {
          name: 'translations',
          text: {
            type: 'simple',
            options: {
              value: 'Переводы',
            },
          },
        },
        {
          name: 'translations',
          text: {
            type: 'simple',
            options: {
              value: 'Переводы',
            },
          },
        },
      ],
    },
  },
  {
    path: '/useobject_groups',
    name: 'UseobjectGroups',
    component: () => import('@/views/useobjectGroups/UseobjectGroups.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'useobjectGroups',
          text: {
            type: 'simple',
            options: {
              value: 'Группы объектов',
            },
          },
        },
      ],
    },
  },
  {
    path: '/subject_registry/:id/:tab?',
    name: 'SubjectRegistryCard',
    component: () => import('@/views/subjects/SubjectsRegistryCard.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'subjects',
          text: {
            type: 'simple',
            options: {
              value: 'Субъекты',
            },
          },
          to: { name: 'SubjectRegistry' },
        },
        {
          name: 'subjectName',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'displayName',
            },
          },
        },
        {
          name: 'tab',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'tab',
            },
          },
        },
      ],
    },
  },
  {
    path: '/identification_configuration_registry',
    name: 'IdentificationConfigurationRegistry',
    component: () =>
      import('@/views/identificationConfigurationRegistry/IdentificationConfigurationRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionary',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'IdentificationConfigurationRegistry',
          text: {
            type: 'simple',
            options: {
              value: 'Конфигурация алгоритмов идентификации',
            },
          },
        },
      ],
    },
  },
  {
    path: '/licenses',
    name: 'LicensesRegistry',
    component: () => import('@/views/licenses/LicensesRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'LicensesRegistry',
          text: {
            type: 'simple',
            options: {
              value: 'Лицензии СМИ',
            },
          },
        },
      ],
    },
  },
  {
    path: '/name-component-registry',
    name: 'NameComponentRegistry',
    component: () => import('@/views/nameComponents/NameComponentRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionary',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'NameComponentRegistry',
          text: {
            type: 'simple',
            options: {
              value: 'Компоненты наименований',
            },
          },
        },
      ],
    },
  },
]

const router = createRouter({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  parseQuery(query) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return qs.parse(query, { arrayFormat: 'brackets' })
  },
  stringifyQuery(query) {
    return qs.stringify(query, { arrayFormat: 'brackets', encode: false })
  },
})

router.beforeEach(async (to, from) => {
  setPageTitle(to.meta.breadcrumb)
  const authStore = useAuthStore()
  const userSettingsStore = useUserSettingsStore()
  if (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (!authStore.currentProfile && !['Login', 'Profiles'].includes(to.name)) ||
    from.name === 'Profiles'
  ) {
    authStore.isAppInitialazing = true
    await api.auth
      .getProfile()
      .then(res => {
        authStore.setCurrentProfile(res)
        userSettingsStore.fetchUserSettings()
        authStore.isAppInitialazing = false
        return true
      })
      .catch(() => (authStore.isAppInitialazing = false))
    //временное решение, пока не будет готова контурная конфигурация
    await api.auth.getContourConfiguration().then(response => {
      authStore.setContourConfiguration(response)
    })
  }
})

export default router
